<template>
  <div class="readings-container">
    <header>
      <ion-img src="/assets/calendar/toma-dia.svg"></ion-img>
      {{ $t("Your Vital Signs for today") }}
    </header>
    <div class="readings">
      <div class="reading tension">
        <ion-icon
          src="assets/calendar/vitals/tension.svg"
          :color="primary"
        ></ion-icon>
        <ion-button
          v-if="!tension"
          color="medium"
          fill="outline"
          size="small"
          @click="triggerCreateTask('toma_tension')"
          >{{ $t("Add reading") }}</ion-button
        >
        <p v-else class="readingValue">
          <strong>{{ new Intl.NumberFormat().format(tension.nombre) }}</strong>
          <span>{{ readingAppend("toma_tension") }}</span>
        </p>
        <p class="readingType">{{ $t(readingConcept("toma_tension")) }}</p>
      </div>
      <div class="reading temperatura">
        <ion-icon
          src="assets/calendar/vitals/temperatura.svg"
          :color="primary"
        ></ion-icon>
        <ion-button
          v-if="!temperatura"
          color="medium"
          fill="outline"
          size="small"
          @click="triggerCreateTask('toma_temperatura')"
          >{{ $t("Add reading") }}</ion-button
        >
        <p v-else class="readingValue">
          <strong>{{
            new Intl.NumberFormat().format(temperatura.nombre)
          }}</strong>
          <span>{{ readingAppend("toma_temperatura") }}</span>
        </p>
        <p class="readingType">{{ $t(readingConcept("toma_temperatura")) }}</p>
      </div>
      <div class="reading peso">
        <ion-icon
          src="assets/calendar/vitals/peso.svg"
          :color="primary"
        ></ion-icon>
        <ion-button
          v-if="!peso"
          color="medium"
          fill="outline"
          size="small"
          @click="triggerCreateTask('toma_peso')"
          >{{ $t("Add reading") }}</ion-button
        >
        <p v-else class="readingValue">
          <strong>{{ new Intl.NumberFormat().format(peso.nombre) }}</strong>
          <span>{{ readingAppend("toma_peso") }}</span>
        </p>
        <p class="readingType">{{ $t(readingConcept("toma_peso")) }}</p>
      </div>
      <div class="reading azucar">
        <ion-icon
          src="assets/calendar/vitals/azucar.svg"
          :color="primary"
        ></ion-icon>
        <ion-button
          v-if="!azucar"
          color="medium"
          fill="outline"
          size="small"
          @click="triggerCreateTask('toma_azucar')"
          >{{ $t("Add reading") }}</ion-button
        >
        <p v-else class="readingValue">
          <strong>{{ new Intl.NumberFormat().format(azucar.nombre) }}</strong>
          <span>{{ readingAppend("toma_azucar") }}</span>
        </p>
        <p class="readingType">{{ $t(readingConcept("toma_azucar")) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { IonIcon, IonImg, IonButton } from "@ionic/vue";
import { computed } from "vue";
import { readingConcept, readingAppend } from "@/helpers";

export default {
  name: "DiaryReadings",
  components: {
    IonIcon,
    IonImg,
    IonButton
  },
  emits: ["triggerCreateTask"],
  props: ["modelValue"],
  setup(props, { emit }) {
    const temperatura = computed(() =>
      props.modelValue.filter(item => item.tipo == "toma_temperatura").pop()
    );
    const tension = computed(() =>
      props.modelValue.filter(item => item.tipo == "toma_tension").pop()
    );
    const peso = computed(() =>
      props.modelValue.filter(item => item.tipo == "toma_peso").pop()
    );
    const azucar = computed(() =>
      props.modelValue.filter(item => item.tipo == "toma_azucar").pop()
    );

    const triggerCreateTask = value => {
      emit("triggerCreateTask", value);
    };

    return {
      readingConcept,
      readingAppend,
      temperatura,
      tension,
      peso,
      azucar,
      triggerCreateTask
    };
  }
};
</script>

<style scoped>
.readings-container {
  width: 100%;
}
header {
  text-align: center;
  padding: 1rem;
  border-bottom: solid 1px var(--border-color);
}
header ion-img {
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.readings {
  display: flex;
  flex-flow: wrap;
  justify-content: stretch;
  align-items: stretch;
  margin: -1px;
}
.reading {
  padding: 1.5rem 1rem 1rem;
  width: 50%;
  border: solid 1px var(--border-color);
  border-bottom: 0;
  position: relative;
}
ion-icon {
  color: var(--ion-color-primary);
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.readingValue {
  margin: 0;
}
.readingValue strong {
  font-size: 1.87em;
  font-weight: normal;
}
.readingValue span {
  color: var(--ion-color-primary);
  padding-left: 0.25rem;
}
.readingType {
  font-size: 0.88em;
  color: var(--ion-color-medium);
  margin: 0;
}
ion-button {
  --border-width: 1px;
  font-size: 0.85em;
}
</style>
