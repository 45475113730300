<template>
  <ion-popover
    :is-open="isCalendarPopoverOpen"
    css-class="calendar-popover"
    :event="eventCalendarPopover"
    @onDidDismiss="setCalendarPopoverOpen(false)"
  >
    <div>
      <v-date-picker
        v-model="dateDataPicker"
        is-expanded
        :attributes="attributes"
        color="orange"
        class="calendar-default"
        @dayclick="onDayClick"
      />
      <ion-button
        size="small"
        fill="clear"
        expand="block"
        class="calendar-popover-close"
        @click="setCalendarPopoverOpen(false, $event)"
        >{{ $t("Close") }}</ion-button
      >
    </div>
  </ion-popover>

  <ion-item
    class="selected-day"
    lines="none"
    @click="setCalendarPopoverOpen(true, $event)"
  >
    <ion-label>
      {{
        store.getters["calendar/diarySelectedDay"].toFormat(
          "EEEE d 'de' MMMM 'de' yyyy"
        )
      }}
    </ion-label>
    <ion-icon :icon="calendarClearOutline"></ion-icon>
  </ion-item>

  <ion-grid class="day-selector">
    <ion-row class="ion-justify-content-between">
      <ion-col size="1" class="back-arrow">
        <button @click.prevent="changeSelectorDays(-1)">
          <svg class="svg-icon" width="26px" height="26px" viewBox="0 -1 16 34">
            <path
              d="M11.196 10c0 0.143-0.071 0.304-0.179 0.411l-7.018 7.018 7.018 7.018c0.107 0.107 0.179 0.268 0.179 0.411s-0.071 0.304-0.179 0.411l-0.893 0.893c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-8.321-8.321c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l8.321-8.321c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l0.893 0.893c0.107 0.107 0.179 0.25 0.179 0.411z"
            ></path>
          </svg>
        </button>
      </ion-col>
      <ion-col
        class="day-item"
        v-for="(day, index) in selectorDays"
        :key="index"
      >
        <button
          :class="setClassSelectorDayButton(day)"
          :style="`--animation-order: ${index};`"
          @click.prevent="changeSelectedDay(day)"
        >
          {{ day.day }}
        </button>
      </ion-col>
      <ion-col size="1" class="forward-arrow">
        <button @click.prevent="changeSelectorDays(1)">
          <svg
            class="svg-icon"
            width="26px"
            height="26px"
            viewBox="-5 -1 16 34"
          >
            <path
              d="M10.625 17.429c0 0.143-0.071 0.304-0.179 0.411l-8.321 8.321c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-0.893-0.893c-0.107-0.107-0.179-0.25-0.179-0.411 0-0.143 0.071-0.304 0.179-0.411l7.018-7.018-7.018-7.018c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l0.893-0.893c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l8.321 8.321c0.107 0.107 0.179 0.268 0.179 0.411z"
            ></path>
          </svg>
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonPopover,
  IonLabel,
  IonButton
} from "@ionic/vue";
import { computed, onBeforeMount, ref, watch, nextTick } from "vue";
import { calendarClearOutline } from "ionicons/icons";
import { DateTime } from "@/plugins/luxon";
import { useStore } from "vuex";

export default {
  name: "DiaryCalendar",
  components: {
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonPopover,
    IonLabel,
    IonButton
  },
  setup() {
    const store = useStore();
    const selectorDays = ref([]);
    const classAnimationIn = ref("animate-in-to-right");
    const selectedDay = computed(
      () => store.getters["calendar/diarySelectedDay"]
    );
    const dateDataPicker = ref(new Date(selectedDay.value.toISO()));

    const changeSelectedDay = async function(day) {
      const existInSelectorDays = selectorDays.value.find(
        selectorDay => selectorDay.valueOf() == day.valueOf()
      );

      let type = 0;
      if (existInSelectorDays == undefined) {
        type = day < store.getters["calendar/diarySelectedDay"] ? -1 : 1;
      }

      if (day != selectedDay.value) {
        await store.dispatch("calendar/changeDiarySelectedDay", day);
      }
      // Si quisiera acceder al valor, tendría que esperar al siguiente tick para que computed me devuelva el prop correcto
      // await nextTick();
      dateDataPicker.value = new Date(day.toISO());

      if (type !== 0) {
        // Animación de salida
        classAnimationIn.value =
          type < 0 ? "animate-out-to-left" : "animate-out-to-right";

        // Hago esperar hasta que termine la animación de salida
        await new Promise(resolve => setTimeout(resolve, 75));

        // Animación de entrada
        classAnimationIn.value =
          type < 0 ? "animate-in-to-left" : "animate-in-to-right";

        selectorDays.value = [];
        await nextTick();

        const monday = day.startOf("week");
        for (var i = 0; i < 7; i++) {
          selectorDays.value.push(monday.plus({ days: i }));
        }
      }
    };

    const changeSelectorDays = async function(type = 0) {
      let monday = selectorDays.value[0];

      // Animación de salida
      classAnimationIn.value =
        type < 0 ? "animate-out-to-left" : "animate-out-to-right";

      // Hago esperar hasta que termine la animación de salida
      await new Promise(resolve => setTimeout(resolve, 75));
      // Resto o sumo una semana al lunes
      monday = monday.plus({ weeks: type });
      // Animación de entrada
      classAnimationIn.value =
        type < 0 ? "animate-in-to-left" : "animate-in-to-right";

      selectorDays.value = [];
      await nextTick();

      for (var i = 0; i < 7; i++) {
        selectorDays.value.push(monday.plus({ days: i }));
      }
    };

    watch(selectedDay, currentValue => {
      changeSelectedDay(currentValue);
    });

    // Doy valores iniciales al selector de días
    onBeforeMount(() => {
      const monday = store.getters["calendar/diarySelectedDay"].startOf("week");
      for (var i = 0; i < 7; i++) {
        selectorDays.value.push(monday.plus({ days: i }));
      }
    });

    const setClassSelectorDayButton = day => {
      const active =
        day.valueOf() == store.getters["calendar/diarySelectedDay"].valueOf()
          ? "active"
          : "";
      return `${active} ${classAnimationIn.value}`;
    };

    // Popover
    const isCalendarPopoverOpen = ref(false);
    const eventCalendarPopover = ref();
    const setCalendarPopoverOpen = (state, e = false) => {
      eventCalendarPopover.value = e;
      isCalendarPopoverOpen.value = state;
    };

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const attributes = computed(() => {
      return [
        {
          key: "before-today",
          highlight: {
            contentClass: "before-today"
          },
          dates: [
            {
              end: yesterday
            }
          ]
        }
      ];
    });

    const onDayClick = function(day) {
      changeSelectedDay(DateTime.fromISO(day.id));
      isCalendarPopoverOpen.value = false;
    };

    return {
      calendarClearOutline,
      selectorDays,
      changeSelectedDay,
      changeSelectorDays,
      setClassSelectorDayButton,
      isCalendarPopoverOpen,
      setCalendarPopoverOpen,
      eventCalendarPopover,
      attributes,
      dateDataPicker,
      onDayClick,
      store
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/custom-variables";

@keyframes animateInToRight {
  0% {
    opacity: 1;
    transform: translateX(8px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes animateOutToRight {
  0% {
    opacity: 1;
  }

  // 50% {
  //   opacity: 1;
  // }

  100% {
    opacity: 0;
    transform: translateX(-8px);
  }
}

@keyframes animateInToLeft {
  0% {
    opacity: 1;
    transform: translateX(-8px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes animateOutToLeft {
  0% {
    opacity: 1;
  }

  // 50% {
  //   opacity: 1;
  // }

  100% {
    opacity: 0;
    transform: translateX(8px);
  }
}

.animate-in-to-right,
.animate-out-to-right,
.animate-in-to-left,
.animate-out-to-left {
  animation-duration: 75ms;
  animation-fill-mode: both;
}

//easeOutQuad
//cubic-bezier(0.5, 1, 0.89, 1);
//easeInQuad
//cubic-bezier(0.11, 0, 0.5, 0);

.animate-in-to-right {
  animation-name: animateInToRight;
  // animation-delay: calc(var(--animation-order) * 50ms);
  animation-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
}

.animate-out-to-right {
  animation-name: animateOutToRight;
  // animation-delay: calc(var(--animation-order) * 50ms);
  animation-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
}

.animate-in-to-left {
  animation-name: animateInToLeft;
  // animation-delay: calc(var(--animation-order) * 50ms);
  animation-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
}

.animate-out-to-left {
  animation-name: animateOutToLeft;
  // animation-delay: calc(var(--animation-order) * 50ms);
  animation-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
}

.day-selector {
  background-color: var(--ion-color-light);
  padding-left: 8px;
  padding-right: 8px;

  ion-col {
    padding-inline-start: 2px;
    padding-inline-end: 2px;

    &.back-arrow,
    &.forward-arrow {
      button {
        padding: 0;
        max-width: 100%;
        background: transparent;
        margin-top: 4px;
        border-radius: 0.25rem;

        .svg-icon {
          max-width: 100%;
          line-height: 0;
          path {
            fill: #ec7a08;
          }
        }

        &:hover {
          background: #edf2f7;
        }
      }
    }

    &.back-arrow {
      text-align: left;
    }

    &.forward-arrow {
      text-align: right;
    }

    &.day-item {
      text-align: center;

      button {
        opacity: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: $color-black;
        line-height: 36px;
        height: 36px;
        width: 36px;
        text-align: center;
        padding: 0;
        background: red;
        border-radius: 50%;
        background: transparent;

        &:hover {
          background: hsla(211, 25%, 84%, 0.3);
        }

        &:focus,
        &.active {
          color: #ffffff;
          background-color: $color-orange;
        }
      }
    }
  }
}

.selected-day {
  font-size: 16px;
  color: $color-black;
  cursor: pointer;
  --padding-start: var(--inner-padding-end);

  ion-label::first-letter {
    text-transform: uppercase;
  }

  ion-icon {
    margin-left: auto;
    font-size: 1.3em;
    color: $color-orange;
  }

  * {
    pointer-events: none;
  }
}
</style>
