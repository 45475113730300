<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{
          $t("{name}'s Diary", { name: activeDependant.nombre })
        }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <diary-calendar></diary-calendar>

      <skeleton-text-list
        v-if="store.state.calendar.loadItems"
      ></skeleton-text-list>
      <div v-else>
        <template v-if="itemsList.length">
          <ion-list class="diary-items-list">
            <ion-item
              v-for="(item, index) in itemsList"
              :key="index"
              :id="`calendar-item-${item.id}`"
              :style="`--animation-order: ${index};`"
              class="animate-in-calendar-items-list"
              href="#"
              detail="false"
              @click.prevent="
                router.push(
                  `/diary-item-detail/${store.getters['calendar/diarySelectedDayString']}/${item.id}`
                )
              "
            >
              <ion-img
                slot="start"
                :src="`/assets/calendar/${item.tipo.split('_')[0]}.svg`"
              ></ion-img>
              <div class="content">
                <ion-label v-if="item.tipo.split('_')[0] !== 'toma'">{{
                  item.tipo != "nota" ? item.nombre : item.descripcion
                }}</ion-label>
                <ion-label v-else>{{ $t(readingTitle(item.tipo)) }}</ion-label>
                <ion-badge
                  v-if="item.tipo != 'nota'"
                  :class="`time ${checkTime(item.fecha_desde)}`"
                  >{{
                    `${DateTime.fromSQL(item.fecha_desde).toLocaleString(
                      DateTime.TIME_24_SIMPLE
                    )}h`
                  }}</ion-badge
                >
                <p v-else class="author">
                  <ion-icon :icon="personOutline"></ion-icon>
                  {{ item.created_by.name }} {{ item.created_by.apellidos }}
                </p>
              </div>
              <ion-checkbox
                v-if="item.tipo == 'tarea'"
                slot="end"
                color="success"
                :checked="item.tarea_completada"
                @click.stop.prevent=""
                @ionChange="setCompletedTask($event, item.id)"
              >
                >
              </ion-checkbox>
              <ion-label v-if="item.tipo.split('_')[0] === 'toma'" slot="end"
                >{{ new Intl.NumberFormat().format(item.nombre) }}
                {{ readingAppend(item.tipo) }}</ion-label
              >
            </ion-item>
            <ion-item
              v-if="readingsList.length"
              :style="`--animation-order: ${itemsList.length};`"
              class="animate-in-calendar-items-list diary-readings"
            >
              <diary-readings
                v-model="readingsList"
                @triggerCreateTask="triggerCreateTask"
              ></diary-readings>
            </ion-item>
          </ion-list>
        </template>
        <div
          v-else
          class="calendar-no-items-list ion-padding animate-in-calendar-items-list"
        >
          <p>No tienes ningún evento para hoy.</p>
          <ion-img src="assets/empty-diary.svg"></ion-img>
        </div>
      </div>

      <main-action-sheet ref="actions"></main-action-sheet>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
  IonImg,
  IonIcon,
  IonCheckbox
} from "@ionic/vue";
import { computed, ref, watch, nextTick, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { DateTime } from "@/plugins/luxon";
import DiaryCalendar from "@/components/DiaryCalendar.vue";
import MainActionSheet from "@/components/MainActionSheet.vue";
import SkeletonTextList from "@/components/SkeletonTextList.vue";
import DiaryReadings from "@/components/DiaryReadings.vue";
import { useRouter } from "vue-router";
import { personOutline } from "ionicons/icons";
import { readingTypes, readingTitle, readingAppend } from "@/helpers";

export default {
  name: "Diary",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    DiaryCalendar,
    DiaryReadings,
    MainActionSheet,
    IonList,
    IonItem,
    IonLabel,
    IonBadge,
    IonImg,
    IonIcon,
    IonCheckbox,
    SkeletonTextList
  },
  setup() {
    const store = useStore();
    const activeDependant = computed(() => store.state.auth.activeDependant);
    const items = computed(() => store.state.calendar.items);
    const itemsList = ref([]);
    const selectedDay = computed(
      () => store.getters["calendar/diarySelectedDay"]
    );
    const readingsList = computed(() =>
      itemsList.value.filter(item => readingTypes.includes(item.tipo))
    );
    const router = useRouter();
    const now = DateTime.now();
    const actions = ref(false);

    const checkTime = date => {
      date = DateTime.fromSQL(date);

      let styleClass = "after-now";
      if (date < now) {
        styleClass = "before-now";
      }
      return styleClass;
    };

    const findDayWithItems = async function(day, $empty_before = true) {
      // Vacío la lista de items
      if ($empty_before) itemsList.value = [];
      let dayWithItems = store.state.calendar.items.find(d => d.date === day);
      if (dayWithItems) {
        if ($empty_before) await nextTick();
        itemsList.value = dayWithItems.items;
      }
    };

    const setCompletedTask = async function(event, id) {
      await store.dispatch("calendar/setCompletedTask", {
        id: id,
        tarea_completada: event.detail.checked ? 1 : 0
      });
    };

    const triggerCreateTask = value => {
      actions.value.showCreateTask(value);
    };

    onBeforeMount(async () => {
      store.dispatch("calendar/setLoadItems", true);
    });

    onMounted(async () => {
      await store.dispatch("calendar/getItems", {
        year: selectedDay.value.year,
        month: selectedDay.value.month
      });
      findDayWithItems(selectedDay.value.toFormat("yyyy-MM-dd"));
    });

    watch(selectedDay, async (currentValue, oldValue) => {
      if (currentValue !== oldValue) {
        if (currentValue.month !== oldValue.month) {
          store.dispatch("calendar/getItems", {
            year: currentValue.year,
            month: currentValue.month
          });
        }
        findDayWithItems(currentValue.toFormat("yyyy-MM-dd"));
      }
    });

    watch(items, async (currentValue, oldValue) => {
      if (currentValue !== oldValue) {
        findDayWithItems(selectedDay.value.toFormat("yyyy-MM-dd"), false);
      }
    });

    watch(activeDependant, async (currentValue, oldValue) => {
      if (currentValue !== oldValue) {
        await store.dispatch("calendar/clearItems");
        await store.dispatch("calendar/getItems", {
          year: selectedDay.value.year,
          month: selectedDay.value.month
        });
      }
    });

    return {
      store,
      itemsList,
      readingsList,
      checkTime,
      DateTime,
      user: computed(() => store.state.auth.user),
      activeDependant,
      selectedDay,
      router,
      personOutline,
      setCompletedTask,
      readingTitle,
      readingAppend,
      actions,
      triggerCreateTask
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/custom-variables";
.action-sheet-diary {
  --button-color: $color-black;

  .action-sheet-button {
    font-size: 16px;
  }

  .action-sheet-group-cancel {
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .action-sheet-button {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      color: $color-orange;

      .action-sheet-button-inner.sc-ion-action-sheet-md {
        justify-content: flex-end;
      }
    }
  }
}

ion-item ion-label[slot="end"] {
  text-align: right;
  color: var(--ion-color-success);
}

ion-item > div {
  max-width: 100%;
}

.diary-readings {
  border-top: solid 1.5rem var(--ion-color-light);
  --padding-start: 0;
  --ion-safe-area-left: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-end: 0;
}
.diary-readings::part(native) .item-inner {
  padding: 0;
}

ion-list {
  margin-bottom: 5rem;
}
</style>
