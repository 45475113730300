<template>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button @click="presentActionSheet">
      <ion-icon :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-modal :is-open="showingCreateTask" @onDidDismiss="showCreateTask(false)">
    <create-task
      @close="showCreateTask(false)"
      mode="create"
      :typeItem="typeCreateTask"
    ></create-task>
  </ion-modal>
</template>

<script>
import {
  IonFab,
  IonFabButton,
  IonIcon,
  actionSheetController,
  IonModal
} from "@ionic/vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { add } from "ionicons/icons";
import CreateTask from "@/components/CreateTask.vue";

export default {
  name: "MainActionSheet",
  components: {
    IonFab,
    IonFabButton,
    IonIcon,
    IonModal,
    CreateTask
  },
  setup() {
    const { t } = useI18n();
    const showingCreateTask = ref(false);
    const typeCreateTask = ref(false);
    const showCreateTask = (value = false) => {
      if (value == false) {
        return (showingCreateTask.value = false);
      }
      if (value !== true) {
        typeCreateTask.value = value;
      }
      showingCreateTask.value = true;
    };

    const buttons = [
      {
        text: t("Add task"),
        handler: () => {
          showCreateTask("tarea");
        }
      },
      {
        text: t("Add reminder"),
        handler: () => {
          showCreateTask("recordatorio");
        }
      },
      {
        text: t("Add note"),
        handler: () => {
          showCreateTask("nota");
        }
      },
      {
        text: t("Measure Blood Pression"),
        handler: () => {
          showCreateTask("toma_tension");
        }
      },
      {
        text: t("Measure Temperature"),
        handler: () => {
          showCreateTask("toma_temperatura");
        }
      },
      {
        text: t("Measure Weight"),
        handler: () => {
          showCreateTask("toma_peso");
        }
      },
      {
        text: t("Measure Blood Sugar"),
        handler: () => {
          showCreateTask("toma_azucar");
        }
      },
      {
        text: t("Cancel"),
        role: "cancel"
      }
    ];

    const presentActionSheet = async function() {
      const actionSheet = await actionSheetController.create({
        cssClass: "action-sheet-diary",
        buttons: buttons
      });

      return actionSheet.present();
    };

    return {
      add,
      presentActionSheet,
      showCreateTask,
      showingCreateTask,
      typeCreateTask
    };
  }
};
</script>

<style></style>
